export const theme = {
  primaryColor: "#FFC709",
  primaryColor20: "#FFF7DD",
  secondaryColor: "#46E9C2",
  secondaryColor20: "#C4F8EF",
  accentColor: "#FF6584",
  accentColor20: "#FFE5EB",
  SecondaryAccentColor: "#9B7BDD",
  black: "#202020",
  black: "#202020",
  white: "#ffffff",
  lightGray: "#F5F5F5",
  mediumGray: "#E2E2E2",
  darkGray: "#707070",
};
