export function isGibberish(string, minLength) {
  if (!string) return true;

  if (string.length < minLength) return true;

  if (string.toLowerCase().includes("test")) return true;

  return detectGibberishFromModel(string);
}

function detectGibberishFromModel(string) {
  var pair;
  var prob = 0.0;
  string = normalizeString(string);
  for (var i = 0; i < string.length - 1; i++) {
    pair = string.charAt(i) + "" + string.charAt(i + 1);
    prob += model[pair];
  }
  return Math.exp(prob / (string.length - 1)) < 0.0187820034731;
}

function normalizeString(line) {
  var validCharacters = "abcdefghijklmnopqrstuvwxyz ";
  line = line.toLowerCase();
  for (var i = 0; i < line.length; i++) {
    if (!validCharacters.includes("" + line.charAt(i))) {
      line = line.substring(0, i) + "#" + line.substring(i + 1);
    }
  }

  line = line.replace(/#/g, "");
  return line;
}

let model = {
  "  ": -3.2910629924454486,
  "c ": -3.8668737299247438,
  aa: -8.569137312930899,
  ab: -3.9369332597631863,
  ac: -3.220670162697391,
  ad: -3.0482479869676102,
  ae: -6.052279063336297,
  af: -4.69956099775001,
  ag: -3.9941585968087816,
  ah: -6.710407217596661,
  ai: -3.2453041060602184,
  aj: -7.060740255010108,
  ak: -4.512283359624297,
  al: -2.4997201529644935,
  am: -3.642636781640966,
  an: -1.5707462805725019,
  ao: -7.978468801653891,
  ap: -3.8936418102220776,
  aq: -9.821900281426267,
  ar: -2.3025283782801376,
  as: -2.348366425382398,
  at: -1.9448651421947813,
  au: -4.539158126663701,
  av: -3.871849760115083,
  aw: -4.706359120463831,
  ax: -6.560313338465017,
  ay: -3.649725323207633,
  az: -6.641954302926283,
  "d ": -0.5394468422260206,
  ba: -2.5528619980785,
  bb: -5.139226208055755,
  bc: -6.049719822245583,
  " a": -2.154456318300654,
  bd: -6.219404795035026,
  " b": -3.132028909232904,
  be: -1.173596307609444,
  " c": -3.204240273221435,
  bf: -8.563954087128105,
  " d": -3.554775966080049,
  bg: -8.805116143944993,
  " e": -3.8320798875631166,
  bh: -8.494961215641153,
  " f": -3.2625149066892174,
  bi: -3.3328454702735173,
  " g": -4.1318426237305275,
  bj: -5.004532700251055,
  " h": -2.7847122791384975,
  bk: -8.805116143944993,
  " i": -2.7534204117779435,
  bl: -2.139085063222716,
  " j": -5.68607428491371,
  bm: -6.121607051758899,
  " k": -5.271518205104294,
  bn: -6.808562262070924,
  " l": -3.779792629468334,
  bo: -2.1459387811703974,
  " m": -3.352468498262208,
  bp: -8.312639658847198,
  " n": -3.8123859357235683,
  bq: -8.900426323749317,
  " o": -2.644532475328425,
  br: -2.719375008855968,
  " p": -3.3676057306367433,
  bs: -3.788438535392774,
  " q": -6.237872536056438,
  bt: -4.703224376087508,
  " r": -3.6809218434868227,
  bu: -2.137350056136624,
  " s": -2.7030074975159986,
  bv: -6.320209494156992,
  " t": -1.86142386740762,
  bw: -7.676650892127201,
  " u": -4.4723885304095425,
  bx: -8.900426323749317,
  " v": -4.918696830481552,
  by: -2.3611294272462486,
  " w": -2.8042850405884527,
  bz: -8.900426323749317,
  " x": -7.783949528282384,
  " y": -4.702039558487341,
  " z": -8.486442571260568,
  "e ": -1.1291364595675442,
  ca: -2.08946313988477,
  cb: -9.398284978640158,
  cc: -3.8466182187208457,
  cd: -7.678499009037193,
  ce: -1.7391136109740999,
  cf: -8.792149175069843,
  cg: -9.580606535434113,
  ch: -1.9093388132314668,
  ci: -2.9331775988939013,
  cj: -9.485296355629789,
  ck: -3.343650033348159,
  cl: -3.276523430584318,
  cm: -8.515895798441685,
  cn: -8.838669190704735,
  co: -1.6000355228278762,
  cp: -9.485296355629789,
  cq: -6.386023403134956,
  cr: -3.377666241879603,
  cs: -5.838186314392146,
  ct: -2.3909106346293085,
  cu: -3.2184408726543063,
  cv: -9.485296355629789,
  cw: -8.838669190704735,
  cx: -9.580606535434113,
  cy: -4.621264535725407,
  cz: -8.299672689972049,
  "f ": -0.9976707550554195,
  da: -3.7200475908408235,
  db: -7.418434550575756,
  dc: -7.88681348409449,
  dd: -4.564865474086475,
  de: -1.9699465394791131,
  df: -6.796896902393934,
  dg: -5.430448837645974,
  dh: -6.754155354016663,
  di: -2.4627220077183942,
  dj: -6.277375571660389,
  dk: -7.666270714480338,
  dl: -4.558326450319418,
  dm: -5.5188785108919935,
  dn: -5.946484744877372,
  do: -3.117009722931763,
  dp: -8.380471304238116,
  dq: -8.136274343726074,
  dr: -3.6243165394601604,
  ds: -3.6811521539816408,
  dt: -7.1936663035345445,
  du: -3.9941983786827824,
  dv: -5.568764165402863,
  dw: -7.077327311450395,
  dx: -9.928033812954128,
  dy: -4.613843097249801,
  dz: -9.745712256160173,
  "g ": -1.0302198528344138,
  ea: -3.0956911156796725,
  eb: -6.3279563530093395,
  ec: -3.711394117529345,
  ed: -2.414309096615009,
  ee: -3.7280101535107066,
  ef: -4.555095809949321,
  eg: -4.9582369569836615,
  eh: -6.340824177620733,
  ei: -4.465115884463944,
  ej: -8.043262266521952,
  ek: -7.012917948982845,
  el: -3.4395652463282116,
  em: -3.7489856294972617,
  en: -2.389153595060143,
  eo: -5.281198278652138,
  ep: -4.426598784036354,
  eq: -6.265131628349503,
  er: -1.976271246918681,
  es: -2.519225987547763,
  et: -3.7331106440131854,
  eu: -6.05129367580717,
  ev: -4.115226028040922,
  ew: -4.719249301196115,
  ex: -4.451178867151557,
  ey: -4.535588241035776,
  ez: -7.731754615658102,
  "h ": -2.3623088007577695,
  fa: -2.7634422911455703,
  fb: -7.9114774546442685,
  fc: -7.529542843946299,
  fd: -8.494623739989885,
  fe: -2.451100566435661,
  ff: -2.926120215794328,
  fg: -7.612234559791412,
  fh: -8.53718335440868,
  fi: -2.4505074466080714,
  fj: -9.033620240722573,
  fk: -8.72823859117139,
  fl: -3.748243385450271,
  fm: -8.839464226281615,
  fn: -7.66534438510536,
  fo: -1.9043799278633868,
  fp: -8.305381740351358,
  fq: -9.370092477343785,
  fr: -2.3558173549839756,
  fs: -5.929674382528349,
  ft: -3.315653131074415,
  fu: -3.5058932812816797,
  fv: -9.370092477343785,
  fw: -7.801476559429941,
  fx: -9.370092477343785,
  fy: -6.135343303319295,
  fz: -9.370092477343785,
  "i ": -3.788398987959084,
  ga: -2.681117318742622,
  gb: -8.560252680876685,
  gc: -8.083328608786376,
  gd: -6.8862762473050125,
  ge: -1.9631827738890724,
  gf: -7.924263914156688,
  gg: -4.61975020412667,
  gh: -2.2213641819441556,
  gi: -2.8612966495974126,
  gj: -9.148039345778804,
  gk: -8.560252680876685,
  gl: -3.35807917488155,
  gm: -6.075346031088684,
  gn: -3.7399711152716475,
  go: -2.831958278425978,
  gp: -7.954116877306369,
  gq: -9.052729165974478,
  gr: -2.5526687518888194,
  gs: -4.062915199691808,
  gt: -4.943346726387837,
  gu: -3.494498087559349,
  gv: -9.148039345778804,
  gw: -7.579423427864958,
  gx: -9.148039345778804,
  gy: -5.882279935011752,
  gz: -8.617411094716633,
  "j ": -4.859215923580492,
  ha: -1.8949866865832032,
  hb: -7.38720654177459,
  hc: -8.05310407988716,
  hd: -7.542278456121169,
  he: -0.7286353651447546,
  hf: -7.858315754328075,
  hg: -9.589971299486425,
  hh: -8.779041083270096,
  hi: -1.9951372153459488,
  hj: -10.100796923252416,
  hk: -7.885223207247999,
  hl: -6.635061020452689,
  hm: -6.261344610659105,
  hn: -6.705170586639715,
  ho: -2.5617698674284206,
  hp: -9.253499062865211,
  hq: -10.100796923252416,
  hr: -4.5720294302077304,
  hs: -6.222675469499951,
  ht: -3.764561336708525,
  hu: -4.622243506401445,
  hv: -9.541181135316993,
  hw: -7.3816968859636205,
  hx: -10.28311848004637,
  hy: -5.024581983510178,
  hz: -10.187808300242045,
  "k ": -1.4288566755523215,
  ia: -3.712244886548001,
  ib: -4.717475282130211,
  ic: -2.783984370515817,
  id: -3.2216013768067646,
  ie: -3.1683365236496233,
  if: -3.90382545586124,
  ig: -3.680790547058952,
  ih: -9.119304544100272,
  ii: -6.422989599216483,
  ij: -10.323277348426208,
  ik: -5.240838322200969,
  il: -3.0795261367137394,
  im: -3.173687619686372,
  in: -1.3126793991869439,
  io: -2.664381175855551,
  ip: -4.9236074876178835,
  iq: -7.895529112478156,
  ir: -3.409705684122631,
  is: -2.051579832828198,
  it: -2.1011937452905483,
  iu: -6.511074678280273,
  iv: -3.8167461832949807,
  iw: -9.672689782285058,
  ix: -6.168308164387673,
  iy: -10.410288725415837,
  iz: -5.511770729440288,
  "l ": -2.0434097729831913,
  ja: -2.3427609160575655,
  jb: -6.1024094410597085,
  jc: -6.037870919922137,
  jd: -6.507874549167873,
  je: -1.4153520955994334,
  jf: -6.245510284700382,
  jg: -6.17140231254666,
  jh: -6.325552992373918,
  ji: -5.51462277615759,
  jj: -6.507874549167873,
  jk: -6.245510284700382,
  jl: -6.412564369363548,
  jm: -6.325552992373918,
  jn: -6.507874549167873,
  jo: -1.2783714986201964,
  jp: -6.412564369363548,
  jq: -6.325552992373918,
  jr: -6.1024094410597085,
  js: -6.1024094410597085,
  jt: -6.245510284700382,
  ju: -1.1002544477293865,
  jv: -6.507874549167873,
  jw: -6.245510284700382,
  jx: -6.507874549167873,
  jy: -6.507874549167873,
  jz: -6.507874549167873,
  "m ": -1.8968318511898539,
  ka: -3.6194933584945135,
  kb: -7.047703539402737,
  kc: -6.062419936041631,
  kd: -7.671857848475732,
  ke: -1.2114318817004575,
  kf: -6.285563487355841,
  kg: -6.824559988088527,
  kh: -3.6311485020862624,
  ki: -1.7851984157338754,
  kj: -7.814958692116405,
  kk: -7.489536291681777,
  kl: -3.88766821455747,
  km: -6.036102627724257,
  kn: -2.3543916630889337,
  ko: -3.8417682258102714,
  kp: -7.546694705521725,
  kq: -7.9820127767795706,
  kr: -5.48206824962703,
  ks: -3.0008999219496366,
  kt: -6.6910285954640045,
  ku: -3.73872587983735,
  kv: -7.2444138336487915,
  kw: -5.438265626968637,
  kx: -8.077322956583895,
  ky: -4.682814563072537,
  kz: -8.077322956583895,
  "n ": -1.4656789800236865,
  la: -2.269183388314388,
  lb: -6.573297799694782,
  lc: -5.745479365449931,
  ld: -2.8596367214634224,
  le: -1.7841347050080587,
  lf: -4.144047900909572,
  lg: -6.844091654118041,
  lh: -7.7437746495925355,
  li: -2.175115871017978,
  lj: -9.701519256294851,
  lk: -4.956587127931601,
  ll: -2.065933606191924,
  lm: -5.060338632783727,
  ln: -6.492693767280152,
  lo: -2.4508837443961715,
  lp: -5.604400767190025,
  lq: -9.701519256294851,
  lr: -5.648286082315182,
  ls: -3.879460040714278,
  lt: -3.864517937852428,
  lu: -3.8209862698941515,
  lv: -5.091361528795721,
  lw: -5.466205750947557,
  lx: -9.883840813088806,
  ly: -2.333600268348952,
  lz: -8.602906967626742,
  "o ": -2.2129736790077486,
  ma: -1.7539942375247688,
  mb: -3.6841198980845076,
  mc: -6.559311098803656,
  md: -8.439623965373157,
  me: -1.3654444296574595,
  mf: -6.4075846625879045,
  mg: -9.337565558579115,
  mh: -8.238953269911006,
  mi: -2.4389427631602505,
  mj: -9.17051147391595,
  mk: -8.902247487321269,
  ml: -6.206031743866062,
  mm: -3.641082386404525,
  mn: -5.662416297277081,
  mo: -2.2280575251437695,
  mp: -2.6972144960398214,
  mq: -9.432875738383439,
  mr: -5.501050105659114,
  ms: -3.5381974599631496,
  mt: -6.907147094075184,
  mu: -3.4685254837670296,
  mv: -9.250554181589486,
  mw: -8.071899185247839,
  mx: -9.432875738383439,
  my: -3.424799925470261,
  mz: -9.432875738383439,
  "p ": -2.9363486388485507,
  na: -3.41157713428474,
  nb: -6.728285208389976,
  nc: -3.0909427430720093,
  nd: -1.740264825689735,
  ne: -2.5015190512398306,
  nf: -4.817371901371758,
  ng: -2.113210530516431,
  nh: -6.807749379744223,
  ni: -3.295792549094097,
  nj: -6.252798951069294,
  nk: -4.917797884336954,
  nl: -4.632769841879079,
  nm: -5.98877585293416,
  nn: -4.66665175664129,
  no: -2.876759518067293,
  np: -7.603753945743875,
  nq: -6.864465609186075,
  nr: -7.187239001449126,
  ns: -3.052336215755031,
  nt: -2.2647479268985964,
  nu: -4.9001580949910455,
  nv: -5.348959516586179,
  nw: -7.14773655847288,
  nx: -7.454376544669275,
  ny: -4.546351845156791,
  nz: -8.807726750069811,
  "q ": -5.540437610773254,
  oa: -5.082241389138844,
  ob: -5.142393631993602,
  oc: -4.276598353328924,
  od: -4.085216817689867,
  oe: -5.759968385010914,
  of: -2.174773615995364,
  og: -5.288621572218711,
  oh: -6.090174110360315,
  oi: -4.4715723308194235,
  oj: -6.90687468303798,
  ok: -4.432658655412265,
  ol: -3.2277431063139637,
  om: -2.8212923556513845,
  on: -1.7681088393713913,
  oo: -3.5156224239092224,
  op: -3.9518016568316168,
  oq: -8.986316224717816,
  or: -2.1664590794141954,
  os: -3.3712131841519417,
  ot: -3.1224483347145418,
  ou: -2.2101893882141153,
  ov: -3.5450716407123704,
  ow: -3.1404797504673136,
  ox: -6.663111844521034,
  oy: -5.51344438505264,
  oz: -7.704225641127928,
  "r ": -1.7281348556024396,
  pa: -2.136265349667144,
  pb: -7.648749930275526,
  pc: -6.522163789565011,
  pd: -8.503165258431594,
  pe: -1.734614577170007,
  pf: -6.50168525822147,
  pg: -7.785325465281278,
  ph: -3.642577960578998,
  pi: -2.654272848600284,
  pj: -8.454375094262163,
  pk: -7.861311372259199,
  pl: -2.3851781986138283,
  pm: -6.363099094935324,
  pn: -7.294204912594619,
  po: -2.116201767527298,
  pp: -2.7907534570773387,
  pq: -9.196312438991539,
  pr: -1.7868731941606955,
  ps: -3.8487288311405847,
  pt: -3.2942257030347744,
  pu: -3.1971277688684197,
  pv: -9.196312438991539,
  pw: -7.0800569241889875,
  px: -9.196312438991539,
  py: -4.987152202340858,
  pz: -9.196312438991539,
  "s ": -0.9911300598753203,
  qa: -6.182291496945648,
  qb: -6.182291496945648,
  qc: -6.182291496945648,
  qd: -6.182291496945648,
  qe: -6.182291496945648,
  qf: -6.182291496945648,
  qg: -6.182291496945648,
  qh: -6.182291496945648,
  qi: -6.182291496945648,
  qj: -6.182291496945648,
  qk: -6.182291496945648,
  ql: -6.182291496945648,
  qm: -6.182291496945648,
  qn: -6.182291496945648,
  qo: -6.182291496945648,
  qp: -6.182291496945648,
  qq: -6.182291496945648,
  qr: -6.182291496945648,
  qs: -6.182291496945648,
  qt: -6.182291496945648,
  qu: -0.057170565024993084,
  qv: -6.182291496945648,
  qw: -6.182291496945648,
  qx: -6.182291496945648,
  qy: -6.182291496945648,
  qz: -6.182291496945648,
  "t ": -1.5835253210583506,
  ra: -2.5722807952437394,
  rb: -5.99205725679638,
  rc: -4.319316764551239,
  rd: -3.7284294070555273,
  re: -1.4225483817556492,
  rf: -5.357231686671419,
  rg: -4.331760927128789,
  rh: -6.033757985995324,
  ri: -2.364541745819138,
  rj: -9.405183209323809,
  rk: -4.850464566831816,
  rl: -4.673556599383159,
  rm: -3.74500437214974,
  rn: -3.916576116332641,
  ro: -2.322164557359624,
  rp: -5.413979406021222,
  rq: -9.0405400957359,
  rr: -3.636862213530037,
  rs: -2.9016138569514562,
  rt: -3.2345687965875074,
  ru: -3.9816438780223904,
  rv: -4.905818082232694,
  rw: -6.257588586460572,
  rx: -10.13915238440401,
  ry: -3.2585970253485272,
  rz: -8.070182142591468,
  "u ": -3.2700350935700784,
  sa: -3.2090119684802527,
  sb: -6.334899523154303,
  sc: -4.093299064417374,
  sd: -7.59203503906228,
  se: -2.1586345703035548,
  sf: -6.182995193953419,
  sg: -7.849503332917564,
  sh: -2.915257814589145,
  si: -2.7754710419906368,
  sj: -9.550291023939893,
  sk: -4.551024457450397,
  sl: -4.707131510786311,
  sm: -4.589272322367449,
  sn: -6.243245073400844,
  so: -2.968928515714967,
  sp: -3.853927304671137,
  sq: -7.00903143760076,
  sr: -8.243133983378726,
  ss: -2.8108514947509753,
  st: -2.1177557637833035,
  su: -3.256550330868065,
  sv: -7.865503674264006,
  sw: -5.380597024476005,
  sx: -10.383200146874996,
  sy: -5.201416596582911,
  sz: -9.913196517629261,
  "v ": -3.1228109057348257,
  ta: -3.1682706209020797,
  tb: -8.217706195099385,
  tc: -5.878899838671022,
  td: -9.525219678366163,
  te: -2.3388918749787213,
  tf: -7.172402459885784,
  tg: -8.478432457562638,
  th: -1.1074718716074006,
  ti: -2.3662341497302157,
  tj: -10.456777882371107,
  tk: -9.689522729657439,
  tl: -4.418539855249544,
  tm: -6.032391973858083,
  tn: -7.21960886445558,
  to: -2.3367153961191565,
  tp: -8.16191483547097,
  tq: -10.719142146838596,
  tr: -3.4624215669877803,
  ts: -3.6797447588047447,
  tt: -4.0251990917417855,
  tu: -3.9002180815630756,
  tv: -9.555991337032916,
  tw: -5.18259559155831,
  tx: -10.131355481936478,
  ty: -4.204725796166784,
  tz: -7.984774637419013,
  "w ": -2.172728230713227,
  ua: -3.6907063474960813,
  ub: -3.7341336409281,
  uc: -3.2454721262149633,
  ud: -4.020608712629845,
  ue: -3.281297978688059,
  uf: -5.0172437658127595,
  ug: -3.1933271849501734,
  uh: -7.7963898885987,
  ui: -3.73111886626001,
  uj: -9.35453450664525,
  uk: -6.208229374611885,
  ul: -2.257675168572427,
  um: -3.4112977818505237,
  un: -2.089862646254399,
  uo: -6.096437968623768,
  up: -3.100705695069777,
  uq: -8.949069398537086,
  ur: -1.905375903432984,
  us: -1.9722545836234537,
  ut: -1.9648676140617645,
  uu: -9.274491798971713,
  uv: -6.7831953510849425,
  uw: -9.35453450664525,
  ux: -7.31765257938421,
  uy: -7.3735330377786665,
  uz: -5.267158613739243,
  "x ": -2.5557039896270073,
  va: -2.4677551327310105,
  vb: -8.46601472297182,
  vc: -8.561324902776146,
  vd: -7.868177722216201,
  ve: -0.5192363285514866,
  vf: -8.561324902776146,
  vg: -8.298960638308655,
  vh: -8.46601472297182,
  vi: -1.7429474349452256,
  vj: -8.46601472297182,
  vk: -7.919471016603751,
  vl: -5.443374996497906,
  vm: -8.561324902776146,
  vn: -4.566800675836256,
  vo: -2.7892609207035393,
  vp: -8.561324902776146,
  vq: -8.561324902776146,
  vr: -6.268790145635601,
  vs: -4.407140340198028,
  vt: -7.973538237874027,
  vu: -6.289199017266808,
  vv: -8.379003345982191,
  vw: -8.030696651713976,
  vx: -8.46601472297182,
  vy: -5.258107929474194,
  vz: -8.561324902776146,
  "y ": -0.3817890191036424,
  wa: -1.596798460957614,
  wb: -7.6001421705956735,
  wc: -7.640964165115928,
  wd: -5.344648685135478,
  we: -1.8921021056868312,
  wf: -6.839336341561913,
  wg: -8.110967794361665,
  wh: -1.6228272590921338,
  wi: -1.7628783045649916,
  wj: -8.87310784640856,
  wk: -7.001305669506969,
  wl: -5.467159861987808,
  wm: -8.179960665848615,
  wn: -3.2183655671770013,
  wo: -2.524218636471301,
  wp: -8.293289351155618,
  wq: -9.209580083029774,
  wr: -4.57000847032435,
  ws: -4.2425484264156506,
  wt: -5.65423202154036,
  wu: -7.560921457442392,
  wv: -9.209580083029774,
  ww: -7.307472556632853,
  wx: -9.209580083029774,
  wy: -6.877436187794184,
  wz: -9.02725852623582,
  "z ": -3.1699327078256037,
  xa: -2.254780968033424,
  xb: -6.898209866138606,
  xc: -2.027603216646053,
  xd: -6.492744758030441,
  xe: -2.462642464536694,
  xf: -5.833499129146177,
  xg: -6.898209866138606,
  xh: -4.310445830910898,
  xi: -2.0731012597852527,
  xj: -6.898209866138606,
  xk: -6.898209866138606,
  xl: -6.42820623689287,
  xm: -6.715888309344651,
  xn: -6.898209866138606,
  xo: -4.4558628307694015,
  xp: -1.4955324842663262,
  xq: -6.3675816150764355,
  xr: -4.636446767664815,
  xs: -5.766807754647505,
  xt: -1.8703897472882491,
  xu: -4.37248122183035,
  xv: -4.348764695213034,
  xw: -6.802899686334281,
  xx: -4.473407140420311,
  xy: -5.175443268397502,
  xz: -6.898209866138606,
  ya: -3.872874353532438,
  yb: -6.07983833755968,
  yc: -5.605956228985375,
  yd: -6.1495716755743555,
  ye: -2.9181087352006836,
  yf: -5.833140189836589,
  yg: -6.998723104610883,
  yh: -7.317176835729417,
  yi: -3.8266394412494407,
  yj: -8.857621876676566,
  yk: -7.45070822835394,
  yl: -4.745382824577915,
  ym: -4.447858487031085,
  yn: -5.448125692199715,
  yo: -2.2255103117197566,
  yp: -4.737230605516364,
  yq: -9.03994343347052,
  yr: -5.751541545953709,
  ys: -3.132676045163294,
  yt: -4.230201081753655,
  yu: -7.038463433260397,
  yv: -7.65364907235063,
  yw: -6.230540738108023,
  yx: -7.70494236673818,
  yy: -8.16447469611662,
  yz: -7.731610613820342,
  za: -2.531179599331457,
  zb: -6.00314605188182,
  zc: -5.907835872077495,
  zd: -4.694813232231641,
  ze: -0.9292230185496455,
  zf: -5.907835872077495,
  zg: -6.00314605188182,
  zh: -3.199785670975285,
  zi: -2.3395844057521735,
  zj: -6.00314605188182,
  zk: -5.666673815260607,
  zl: -3.886890537079268,
  zm: -4.0016660516716955,
  zn: -5.009894278871537,
  zo: -1.7362497244615696,
  zp: -6.00314605188182,
  zq: -6.00314605188182,
  zr: -6.00314605188182,
  zs: -5.820824495087865,
  zt: -5.907835872077495,
  zu: -3.1183453390351104,
  zv: -5.666673815260607,
  zw: -5.907835872077495,
  zx: -6.00314605188182,
  zy: -4.568061526592497,
  zz: -3.7731316517226094,
  "a ": -2.7134701747591117,
  "b ": -4.738423113053401,
};
